<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!--            <label>显示</label>-->
            <!--            <v-select-->
            <!--              v-model="limit"-->
            <!--              :options="limitOptions"-->
            <!--              :clearable="false"-->
            <!--              class="per-page-selector d-inline-block mx-50"-->
            <!--            />-->
            <!--            <label>条</label>-->
            <div class="demo-spacing-0">
              <b-alert
                  variant="primary"
                  show
              >
                <div class="alert-body">
                  <span><strong>店铺名称:{{ storeInformation.store_name }}</strong> </span>
                </div>
              </b-alert>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  variant="primary"
                  class="mr-1"
                  :to="{ name: 'apps-storecontact-edit',query:{storeId:store_id}}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-store-list'}"
              >
                <span class="text-nowrap">返回店铺列表</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :order-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :order-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <!-- Column: Type -->
        <template #cell(store_id)="data">
          #[{{data.item.store_id}}]{{ getCodeLabel('store', data.item.store_id) }}
        </template>

        <template #cell(user_id)="data">
          {{ getCodeLabel('user', data.item.user_id) }}
        </template>

        <template #cell(user_mobile)="data">
          {{ data.item.ext.user.mobile }}
        </template>


        <template #cell(is_default)="data">
          <feather-icon
              icon="XIcon"
              size="21"
              v-if="data.item.is_default==0"
          />
          <feather-icon
              icon="CheckIcon"
              size="21"
              v-else
          />
        </template>


        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <!--            <b-dropdown-item :to="{ name: 'apps-storecontact-view', query: { id: data.item.id } }">-->
            <!--              <feather-icon icon="FileTextIcon" />-->
            <!--              <span class="align-middle ml-50">浏览</span>-->
            <!--            </b-dropdown-item>-->

            <b-dropdown-item :to="{ name: 'apps-storecontact-edit', query: { id: data.item.id,storeId:store_id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import storecontactUseList from './storecontactUseList'
import storecontactStore from './storecontactStore'
import {useRouter} from "@core/utils/utils";
import storeStore from "@/views/apps/store/storeStore";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('storecontact/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  data() {
    return {
      storeInformation: {},
      store_id: 0,
    }
  },
  setup() {
    // Register module
    if (!store.hasModule('storecontact')) store.registerModule('storecontact', storecontactStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('storecontact')) store.unregisterModule('storecontact')
      if (store.hasModule('store')) store.unregisterModule('store')
    })

    const router = useRouter()

    const getStore = function () {
      store.dispatch('store/view', {id: router.route.value.query.storeId}).then(res => {
        this.storeInformation = res.data.data
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = storecontactUseList(router.route.value.query.storeId)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      getStore,
    }
  },
  created() {
    this.store_id = this.$route.query.storeId || 0
    this.getStore()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
